.editor-div {
  padding: 0;
  background: var(--color-bg-primary);
  width: 100%;
  outline: none;
}

.note {
  /*
    The note gets an arbitrarily large margin on both sides so we can click at the end or the beginning
    of the edit zone and get the caret on the corresponding line. It makes selecting text and positioning the caret easier overall
  */
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  /* This value is purposefully equal to contentMaxWidth in EditorPage.tsx */
  max-width: 720px;
  border-bottom: 1px solid var(--color-border-primary);
  padding: 24px 0;
  position: relative;
  word-break: break-word;
  overflow-wrap: break-word;
}

.isCollapsable .note {
  padding: 8px;
}
.note:last-child {
  border-bottom: 0;
}
/* https://web.dev/content-visibility/ introduces bugs, because it assumes the children are all contained in the parent. */

.note:first-child {
  border-top: none;
}

.editor-div.not-loaded.not-initial {
  opacity: 0.2;
}

.editor-div.not-loaded.not-initial:focus {
  outline: none;
}
.backlink-item button:hover {
  color: var(--color-text-primary);
  background: var(--color-bg-secondary);
}

.backlinks {
  white-space: initial;
  color: var(--color-text-accent);
  margin-top: 12px;
  user-select: none;
}

.backlinks .backlink-button {
  border: 0;
  background: none;
  color: var(--color-text-secondary);
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 8px;
}

.backlinks .backlink-button:hover {
  opacity: 0.7;
}

.backlinks .backlink-button::before {
  content: "▶ ";
}

.backlinks .backlink-button.expanded::before {
  content: "▼ ";
}

.backlink-list {
  max-height: 800px;
  overflow-y: auto;
}

.backlink-item {
  color: var(--color-text-primary);
  background: var(--color-bg-secondary);
  padding: 8px 12px;
  border-radius: 6px;
  margin-bottom: 8px;
  position: relative;
}

.baclink-item:last-child {
  margin-bottom: 0;
}

.backlink-item .title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 6px;
  color: var(--color-text-secondary);
}

.backlink-item button {
  position: absolute;
  top: 5px;
  right: 4px;
  user-select: none;
}

.backlink-item .jumpToButton {
  right: 100px;
}

.note-debug-button {
  position: absolute;
  right: -70px;
  font-size: 14px;
  padding: 0px 7px;
  user-select: none;
  -webkit-user-select: none;
  z-index: 0;
  border-radius: 8px;

  font-family: monospace;
  letter-spacing: -1px;
  transform: scaleX(0.9);
  transform-origin: 100% 0%;
}

/* Spaceship Expansion & backlinks */

.expandedNote {
  background: var(--color-bg-secondary);
  border-radius: var(--radius-medium);
  position: relative;
  margin: 12px 0;
  padding-left: 4px;
}

.expandedNote::before {
  content: "";
  border-top-left-radius: var(--radius-medium);
  border-bottom-left-radius: var(--radius-medium);
  height: 100%;
  background: var(--color-border-secondary);
  width: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.expandedNote::after {
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  font-size: 14px;
  color: var(--color-text-secondary);
}

.expandedNote .note {
  border-bottom: 0;
  padding: 12px 18px;
}

.expandedNote .expandedNote::after {
  content: "";
}

.expandedNote.highlighted {
  border-left-color: var(--color-text-accent);
  background: var(--color-bg-accent-quaternary);
}

.linkLoader {
  padding: 0px 10px;
}

.dot {
  height: 16px;
  width: 16px;
  right: 0;
  bottom: 5px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
}
