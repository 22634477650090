/* All the extra on top of the base Prosemirror */
.ProseMirror-selectednode.paragraph {
  outline: 0px solid transparent;
}

.checkbox-wrapper {
  margin: 0 2px;
  padding-right: 4px;
  display: inline-block;
  width: 1em;
  height: 1em;
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  top: 2px;
  left: 0;
  cursor: pointer;
  transition: var(--transition-fast);
}
input.checkbox {
  margin: 0;
  position: relative;
  top: -1px;
  left: 0;
  width: 1em;
  height: 1em;
  display: inline-block;
  -webkit-appearance: none; /* Safari only  */
}
.checkbox-wrapper::before {
  content: "";
  display: inline-block;
  border: 2px solid var(--color-text-primary);
  height: 1em;
  width: 1em;
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: var(--transition-fast);
}
.checkbox-wrapper:hover {
  transform: scale(1.1);
}
.checkbox-wrapper:active {
  transform: scale(0.9);
}

.checkbox-wrapper:hover::before,
.checkbox-wrapper:focus::before {
  background: var(--color-bg-tertiary);
}
.checkbox-wrapper.checked::before {
  background: var(--color-text-accent);
  border: 0;
}
.checkbox-wrapper.checked::after {
  content: "";
  display: block;
  border: 0;
  height: 0.2em;
  width: 0.5em;
  border-left: 2.5px solid var(--color-bg-primary);
  border-bottom: 2.5px solid var(--color-bg-primary);
  transform: translate(-50%, -50%) rotate(-45deg);
  transform-origin: center center;
  position: absolute;
  top: 0.43em;
  left: 0.5em;
  z-index: 2;
}

.note-divider {
  user-select: none;
  color: var(--color-text-secondary);
  top: -8px;
  font-size: 12px;
  position: absolute;
  width: 100%;
  margin: auto;
  text-align: center;
}

.note-divider span {
  background-color: var(--color-bg-primary);
  padding: 4px;
}

.note-jump-to-button {
  position: absolute !important; /* microtip has a more specific selector putting it at relative, could change that later */
  right: 0px;
  top: 3px;
  padding: 0 7px;
  user-select: none;
  z-index: 0;
  border-radius: 8px;
  font-size: 12px;
  background-color: var(--color-bg-primary);
  color: var(--color-text-secondary);
  border: 1px solid var(--color-border-secondary);
  cursor: pointer;
}

@keyframes yellowFadeOut {
  from {
    background: yellow;
  }
  to {
    background: inherit;
  }
}

.note.highlighted {
  animation-name: yellowFadeOut;
  animation-duration: 1s;
}

p.paragraph {
  padding: 0;
  margin: 0.45em 0;
  white-space: pre-wrap;
}

code.codeblock {
  background-color: var(--color-bg-sub);
  /* border: 1px solid var(--color-border-secondary); */
  display: block;
  padding: 8px 8px;
  font-size: 14px;
  border-radius: 4px;
  margin: 8px 0;
}

/*
* Setting user-select: none on embeds (islands in contenteditable are non-contenteditable)
* has interesting effects in different browsers.  On iOS Safari, it solves a problem where
* moving the text cursor using the keyboard "trackpad" (force touch on the keyboard) would
* get stuck when the cursor touches the bounding box of an embed, making it hard to position
* the text cursor.  With user-select: none, the text cursor passes over embeds with no problem
* and you can keep moving it.  On Chrome desktop, the effect of user-select: none is actually a bad
* one, as it makes the selection highlight skip embeds.  On iOS Safari with user-select: none,
* this occurs but only when the embed is at the start of a line.  On Android Chrome, on my
* Samsung Tablet, dragging the text cursor across an embed cancels the interaction regardless of
* whether user-select: none is set or not.  Note that the embed itself can still be selected when
* user-select: none is set.
*/
/* .editor-div-ios .embed {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
} */

.clickable {
  cursor: pointer;
}

body.CtrlMetaPressed .embed,
body.CtrlMetaPressed .hashtag,
body.CtrlMetaPressed .link {
  cursor: initial;
}

.embed {
  background-color: var(--color-bg-accent-quaternary);
  color: var(--color-text-accent);
  /* if we put too much vertical padding on a mention (more than 1px?),
  then mentions on adjacent lines overlap, unless we bump up the line height,
  but then the text cursor on iOS gets taller and it looks a little off, and
  the text is less compact */
  padding: 0;
  border-radius: 4px;
  font-weight: var(--highlight-font-weight);
}

.spaceship.embed[data-isexpanded="true"] {
  color: var(--color-bg-primary);
  background: var(--color-bg-accent-primary);
}

.embed:hover,
.ProseMirror-selectednode.embed,
.embed[isHighlighted="true"] {
  color: var(--color-text-accent);
  background: var(--color-bg-accent-tertiary);
  outline: 0 solid transparent;
}

.embed.not-clickable,
.embed.not-clickable:hover,
.ProseMirror-selectednode.embed.not-clickable {
  cursor: not-allowed;
  background: var(--color-bg-secondary);
  color: var(--color-text-secondary);
}

.prosemirror-suggestion {
  color: var(--color-text-accent);
  background: var(--color-bg-accent-quaternary);
  border-radius: 4px;
  padding: 0 4px;
}

.hashtag {
  color: var(--color-text-accent);
  font-weight: var(--highlight-font-weight);
}

.link {
  color: var(--color-text-accent);
  position: relative;
  padding: 2px 0px;
  font-weight: var(--highlight-font-weight);
  word-break: break-all;
  text-decoration: none;
  transition: var(--transition-heavy);
  background: linear-gradient(
      to right,
      var(--color-bg-accent-secondary) 100%,
      transparent 75%
    )
    0 1.3em / 8px 1px repeat-x;
}

.link:hover.link::after {
  opacity: 1;
}

.link:hover.link::before {
  content: "";
  width: 100%;
  display: block;
  height: 1.1em;
  position: absolute;
  transform: rotate(-1.5deg);
  left: 0;
  top: calc(1em - 4px);
}

.suggestion-item-list-container {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  background-color: var(--color-bg-primary);
  border-radius: 4px;
  position: absolute;
  z-index: 20;
  overflow-y: auto;
  overflow-x: hidden;

  transition: filter 0.6s;
}

.suggestion-item-list-container.disabled {
  filter: grayscale(100%);
}

.suggestion-item-list {
  border-radius: 4px;
  width: 500px;
  overflow: hidden;
}

.suggestion-item {
  padding: 6px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestion {
  color: gray;
}

.suggestion-item-active {
  background-color: var(--color-bg-accent-quaternary);
  font-weight: var(--highlight-font-weight);
}

.suggestion-create {
  color: var(--color-text-secondary);
  font-style: italic;
}

.suggestion-item-active,
.suggestion-item-active.suggestion-create {
  color: var(--color-text-accent);
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}

/* regular ellipsis */
.ellipsis {
  display: none;
}
.ellipsis-dot {
  display: inline;
  border-radius: 4px;
  padding: 0 4px;
  cursor: pointer;
  color: var(--color-text-tertiary);
}
.ellipsis-dot-list-item {
  display: list-item;
}

/* expanded ellipsis */
.isExpanded > :not(.expandedNote):not(.backlinksSection) .ellipsis {
  display: block;
}

.isExpanded > :not(.expandedNote):not(.backlinksSection) .ellipsis-dot {
  display: none;
}

.expand-button {
  cursor: pointer;
  font-size: 12px;
  color: var(--color-text-secondary);
  padding: 8px;
}

.expand-button:hover {
  background-color: var(--color-bg-tertiary);
}
.search-query-match {
  background-color: var(--color-bg-highlight);
  color: var(--color-text-searchHighlight);
}

.underline {
  text-decoration: underline;
}

.bullet-list {
  margin-block-start: 0;
  margin-block-end: 0;
}
