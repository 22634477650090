.tree-item {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
}

.clone {
  display: inline-block;
  pointer-events: none;
}

.clone > .tree-item {
  padding-right: 24px;
  border-radius: 4px;
  box-shadow: 0 15px 15px 0 rgba(34, 33, 81, 0.1);
}

.ghost {
  opacity: 0.5;
}

.collapse-container {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-medium);
  color: var(--color-text-secondary);
  background-color: transparent;
}

.collapse-collapsed {
  transform: rotate(-90deg);
}
.grab-handle {
  width: 20px;
  align-self: stretch;
  justify-self: flex-end;
  cursor: pointer;
  /* Prevent the mobile browsers from scrolling the container when the grab handle is dragged */
  touch-action: none;
  background: url("data:image/svg+xml;utf8,<svg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='12'><path d='M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z'></path></svg>")
    no-repeat center;
}
