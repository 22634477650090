.selected-link {
  color: var(--color-text-accent);
  text-decoration: none;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 5px 8px;
  margin-bottom: 4px;
  border-radius: 5px;
  font-weight: var(--highlight-font-weight);
  background-color: transparent;
  transition: var(--transition-light);
  border: 0;
  width: 100%;
  line-height: 1.4em;
  position: relative;
}

.selected-link:hover {
    background-color: var(--color-bg-tertiary);
}

.selected-link.selected, .selected-link.selected:hover {
  color: var(--color-bg-primary);
  background-color: var(--color-text-accent);
}

.selected-link:focus {
  outline: 2px solid var(--color-text-accent);
}
.selected-link.selected:focus {
  outline: 2px solid transparent;
}

.selected-link:focus:not(:hover)::before,
.selected-link:focus:not(:hover)::after {
  opacity: 0;
}
