@keyframes slideTop {
    0% {
    top: -300px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
