/* 
Menu working with overlays, z-index hygiene is relatively important:
0-9: reserved for the editor
10: semi opaque background, the ones that can be clicked to close the menu
11: action strip and expanded menu , basically the menu
20: toast messages, that needs to appear above all the rest of the UI.

*/
/* the action strip itself, it is positioned dynamically via the element style directly  */
.note-menu-actions {
  display: flex;
  position: relative;
  float: left;
  background: var(--color-bg-elevated);
  border: 1px solid var(--color-border-secondary);
  box-shadow: var(--shadow-light);
  padding: 2px 2px;
  z-index: 11;
  border-radius: var(--radius-large);
}

/* button in the action strip */
.note-menu-button {
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  border-radius: var(--radius-medium);
  min-width: 32px;
  min-height: 32px;
  z-index: 11;
  color: var(--color-text-secondary);
}
.note-menu-button:hover,
.note-menu-button:focus,
.note-menu-button.expanded {
  outline: 0;
  background: var(--color-bg-tertiary);
}

.note-menu-button.active {
  color: var(--color-text-accent);
}

.note-menu-button img {
  display: block;
}

.note-menu-button.folder-icon {
  padding: 4px 4px 4px 8px;
}

/* horizontal line in the action */
.note-menu-spacer {
  height: 8px;
  display: block;
  width: 100%;
}

/* the expanded panel that contains a secondary list of actions */
.note-menu-expanded {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 180px;
  border: 1px solid var(--color-border-secondary);
  background: var(--color-bg-elevated);
  box-shadow: var(--shadow-heavy);
  padding: 4px 0px 8px;
  border-radius: var(--radius-large);
  z-index: 11;
  font-size: 14px;
}

/* a menu item */
.menu-item,
button.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 6px 12px;
  height: 36px;
  text-align: left;
  font-size: 14px;
  border: none;
  z-index: 11;
  background-color: var(--color-bg-elevated);
  color: var(--color-text-primary);
}

.menu-item:hover,
button.menu-item:hover {
  background-color: var(--color-bg-tertiary);
}

button.folder {
  padding-left: 8px;
}

/* little folder imagets on the left */
.folder img,
.folder span {
  display: block;
  margin-left: 8px;
}

/* used for the list of folders right now */
.note-menu-list {
  max-height: 50vh;
  overflow-y: auto;
}
/* new folder related elements */
.newFolderContainer {
  display: flex;
  flex-direction: row;
  position: relative;
}
.newFolderContainer input,
.newFolderContainer input:focus {
  background-color: var(--color-bg-sub);
  height: 34px;
  border-radius: 4px;
  margin: 0px 8px;
  color: var(--color-text-primary);
  text-indent: 8px;
  width: calc(100% - 16px);
  outline: none !important;
  border: none;
}
.newFolderContainer input:focus {
  box-shadow: 0 0 0 3px var(--color-bg-accent-secondary);
}
button.enter-button,
button.enter-button:hover {
  height: 20px;
  position: absolute;
  background: transparent;
  border: none;
  outline: none;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.enter-button svg {
  margin: 0px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  background: var(--color-bg-tertiary);
  border-radius: var(--radius-medium);
  color: var(--color-text-secondary);
}

/* created at, updated at in the more menu */
.menu-info {
  padding: 0px 12px;
  width: 100%;
  text-align: left;
  font-size: 12px;
  color: var(--color-text-tertiary);
}

.note-menu-mobile {
  position: absolute;
  right: 8px;
  top: 1px;
  user-select: none;
}

.note-menu-button-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  border-radius: var(--radius-medium);
  width: 32px;
  height: 32px;
  padding: 0;
  color: var(--color-text-tertiary);
}

/* Without the media query, on mobile devices without a pointer device the
button gets stuck in a hover state when you tap it.
See https://stackoverflow.com/questions/17233804/how-to-prevent-sticky-hover-effects-for-buttons-on-touch-devices */
@media (hover: hover) {
  .note-menu-button-mobile:hover {
    background: var(--color-bg-secondary);
  }
}

.note-menu-button-mobile.active {
  background: var(--color-bg-accent-secondary);
}

/* toast animation */
@keyframes toast-animation {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-30px);
  }
  10% {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }
  90% {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-30px);
  }
}

.note-menu-toast {
  position: fixed;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 14px;
  border-radius: 5px;
  background: var(--color-bg-secondary);
  backdrop-filter: blur(16px);
  z-index: 20;
  opacity: 1;
  box-shadow: 0px 4px 30px 0px rgb(0 0 0 / 10%);
  border: 1px solid var(--color-text-accent);
  background: var(--color-bg-accent-quaternary);
  color: var(--color-text-accent);
  -webkit-user-select: none;
  user-select: none;
  animation: 2.4s toast-animation forwards;
  white-space: nowrap;
  text-align: center;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.note-menu-toast > button {
  all: unset;
  display: block;
  cursor: pointer;
  text-decoration: underline;
}
