.sync-icon div {
  transition: all 0.5s ease;
}
.sync-icon.info div {
  border-color: var(--color-bg-ok);
}
.sync-icon.offline div {
  border-color: var(--color-bg-offline);
}

.sync-icon.unknown div {
  border-color: var(--color-bg-tertiary);
}

.sync-icon.warning div {
  border-color: var(--color-bg-warning);
}

.sync-icon.error div {
  border-color: var(--color-bg-error);
}
