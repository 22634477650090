/* layout used for global values */
* {
  box-sizing: border-box;
}

:root {
  /* used for the toggle */
  --handle-size: 10px;
}

html {
  height: -webkit-fill-available;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica : Neue", Helvetica,
    Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.005rem;
  color: var(--color-text-primary);
  text-rendering: optimizeLegibility;
  background-color: var(--color-bg-primary);
  margin: 0;
  padding: 0;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
}

#__next {
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  max-width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

body {
  --header-height: 56px;

  background-color: var(--color-bg-primary);

  --highlight-font-weight: normal;
  --transparent: #ffffff00;

  --color-text-strong: #1e1e1e;
  --color-text-primary: #343737;
  --color-text-secondary: #737777;
  --color-text-tertiary: #abaeae;

  --color-text-accent: #118b96;
  --color-text-positive: #1e6a1a;
  --color-text-negative: #a91818;
  --color-text-searchHighlight: #452610;

  --color-bg-primary: #fff;
  --color-bg-secondary: #eff2f2;
  --color-bg-tertiary: #eaeded;
  --color-bg-sub: var(--color-bg-secondary);
  --color-bg-elevated: var(--color-bg-primary);

  --color-bg-accent-primary: #00a0ad;
  --color-bg-accent-secondary: #00a0ad60;
  --color-bg-accent-tertiary: #00a0ad35;
  --color-bg-accent-quaternary: #00a0ad10;

  --color-bg-positive-primary: #7fdb72;
  --color-bg-positive-secondary: #7fdb7250;
  --color-bg-positive-tertiary: #7fdb7215;

  --color-bg-negative-primary: #ff7a7a;
  --color-bg-negative-secondary: #ff7a7a50;
  --color-bg-negative-tertiary: #ff7a7a15;

  --color-bg-ok: rgb(0, 158, 29);
  --color-bg-offline: #FFD580;
  --color-bg-warning: #FF5733;
  --color-bg-error: red;

  --color-border-primary: #0b233924;
  --color-border-secondary: #00000015;
  --color-border-secondary-solid: #e8e9ea;

  --color-border-alert: #fac584;

  --color-bg-highlight: #ffde5c;

  --shadow-light: 0px 1px 5px -1px rgba(0, 0, 0, 0.14);
  --shadow-medium: 0px 2px 10px -3px rgba(0, 0, 0, 0.16);
  --shadow-heavy: 0px 4px 16px -4px rgba(0, 0, 0, 0.22);

  --transition-light: 0.06s cubic-bezier(0.4, 0, 0.2, 1) all;
  --transition-medium: 0.1s cubic-bezier(0.4, 0, 0.2, 1) all;
  --transition-heavy: 0.2s cubic-bezier(0.4, 0, 0.2, 1) all;

  --radius-small: 0.125rem;
  --radius-medium: 0.25rem;
  --radius-large: 0.325rem;
}

body[data-theme="dark"] {
  --color-text-strong: #f7f7f7;
  --color-text-primary: rgba(255, 255, 255, 0.96);
  --color-text-secondary: rgba(255, 255, 255, 0.62);
  --color-text-tertiary: rgba(255, 255, 255, 0.44);
  --color-text-accent: #5dcfdb;
  --color-text-positive: #7fdb72;
  --color-text-negative: #ffb4a9;
  --color-text-searchHighlight: #fff50d;

  --color-bg-primary: #303030;
  --color-bg-secondary: #424242;
  --color-bg-tertiary: #4c4c4c;
  --color-bg-sub: #292929;
  --color-bg-elevated: var(--color-bg-secondary);

  --color-bg-accent-primary: #20bdcb;
  --color-bg-accent-secondary: #20bdcb60;
  --color-bg-accent-tertiary: #20bdcb30;
  --color-bg-accent-quaternary: #20bdcb15;

  --color-bg-positive-primary: #65bf5a;
  --color-bg-positive-secondary: #65bf5a45;
  --color-bg-positive-tertiary: #65bf5a17;

  --color-bg-negative-primary: #ff5449;
  --color-bg-negative-secondary: #ff544950;
  --color-bg-negative-tertiary: #ff544917;

  --color-border-primary: #ffffff30;
  --color-border-secondary: #ffffff15;
  --color-border-secondary-solid: #474849;

  --color-bg-highlight: #6f5819;

  --transparent: #31313100;

  letter-spacing: 0.007em;
}

button {
  cursor: pointer;
}

.nonselectable {
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-text-tertiary);
  border-radius: 10px;
}

.spinner {
  animation: rotate 2.5s linear 0s infinite normal forwards;
}

.show-more-button {
  /* 
  The following is necessary to preserve the correct z order of elements.
  Without it the button is obstructed by the autocomplete container.
  */
  position: relative;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
